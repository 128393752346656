import axios from "axios";

import { AuthApi } from "@/utils/fetchData";

const BASE_URL = process.env.NEXT_PUBLIC_SERVICE_URL;
const NO_AUTH_ROUTES = ["/users/login/", "/users/logout/", "/users/refresh/"];

export const localAccess = () => {
  const accessToken =
    typeof window !== "undefined" ? localStorage.getItem("access_token") : "";
  if (typeof window !== "undefined") {
    return accessToken;
  }
};

const getAccessToken = () => {
  if (typeof window !== "undefined") {
    return localStorage.getItem("access_token");
  }
  return null;
};

const getRefreshToken = () => {
  if (typeof window !== "undefined") {
    return localStorage.getItem("refresh_token");
  }
  return null;
};

const clearTokensAndRedirect = () => {
  if (typeof window !== "undefined") {
    localStorage.clear();
    window.location.href = "/";
  }
};

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

// 요청 인터셉터 - headers
axiosInstance.interceptors.request.use(
  (config) => {
    const url = config.url || "";

    if (NO_AUTH_ROUTES.includes(url)) {
      delete config.headers.Authorization;
      return config;
    }

    const token = getAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    }

    clearTokensAndRedirect();
    return config;
  },
  (error) => Promise.reject(error)
);

// 응답 인터셉터 - refresh
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refresh = getRefreshToken();
        if (!refresh) {
          clearTokensAndRedirect();
          return;
        }

        const response = await AuthApi.postRefresh(refresh);
        const { access: newAccessToken, refresh: newRefreshToken } = response.data.token;

        if (typeof window !== "undefined") {
          localStorage.setItem("access_token", newAccessToken);
          localStorage.setItem("refresh_token", newRefreshToken);
        }

        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        clearTokensAndRedirect();
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
