/** @jsxImportSource @emotion/react */
"use client";

import "react-toastify/dist/ReactToastify.css";

import { Box, Flex, FormControl, useBreakpointValue, VStack } from "@chakra-ui/react";
import { keyframes } from "@emotion/react";
import { Button, Input, TypoGraph } from "@upsightdevs/upsight-design-system";
import i18next from "i18next";
import dynamic from "next/dynamic";
import Image from "next/image";
import { useRouter } from "next/navigation";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";

import { usePostLogin } from "@/api/AuthApi/postLogin";
import { useTranslation } from "@/app/i18n/client";
import Dropdown from "@/components/Dropdown/Dropdown";
import Loading from "@/components/Loading/Loading";

/** @jsxImportSource @emotion/react */

require("dotenv").config();

const DynamicToastContainer = dynamic(
  () => import("react-toastify").then((mod) => mod.ToastContainer),
  {
    ssr: false,
  }
);

interface IFORM {
  email: string;
  password: string;
}

const shakeAnimation = keyframes`
  0% { transform: translateX(0); }
  25% { transform: translateX(5px); }
  50% { transform: translateX(-5px); }
  75% { transform: translateX(5px); }
  100% { transform: translateX(0); }
`;

const items = [
  { label: "한국어", value: "ko" },
  { label: "English", value: "en" },
];

const LoginPage = ({ params: { lng } }: { params: { lng: string } }) => {
  const router = useRouter();
  const { t, i18n, ready } = useTranslation(lng);
  const [input, setInput] = useState<IFORM>({ email: "", password: "" });
  const [isLanguageSet, setIsLanguageSet] = useState(false);
  const [loginError, setLoginError] = useState<string | null>(null);
  const [isShaking, setIsShaking] = useState(false);
  const [inputErrors, setInputErrors] = useState({ email: false, password: false });
  const { mutate: postLogin, isSuccess } = usePostLogin();

  const inputWidth = useBreakpointValue({ base: "311px", md: "360px", lg: "574px" });

  useEffect(() => {
    const setLanguage = async () => {
      if (i18n.language !== lng) {
        await i18n.changeLanguage(lng);
      }
      setIsLanguageSet(true);
    };

    setLanguage();
  }, [lng, i18n]);

  useEffect(() => {
    if (isSuccess) {
      router.push(`/${lng}/RegisterConstr`);
    }
  }, [isSuccess]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
    setLoginError(null);
    setInputErrors({ ...inputErrors, [name]: false });
  };

  const handleInputFocus = (name: string) => {
    setInputErrors({ ...inputErrors, [name]: false });
  };

  const handleLanguageChange = (option: string) => {
    i18next.changeLanguage(option);
    router.replace(window.location.href.replace(`/${lng}`, `/${option}`));
  };

  const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!input.email || !input.password) {
      setLoginError(t("id-pw-required"));
      setIsShaking(true);
      setInputErrors({
        email: !input.email,
        password: !input.password,
      });
      setTimeout(() => setIsShaking(false), 500);
      return;
    }

    const formData = new FormData();
    formData.append("email", input.email);
    formData.append("password", input.password);

    postLogin(formData);

  };

  if (!ready || !isLanguageSet) {
    return <Loading />;
  }

  return (
    <>
      <DynamicToastContainer />
      <Flex minHeight="100vh">
        <Box position="absolute" top={32} right={32} zIndex="20" fontSize="12px">
          <Dropdown
            items={items}
            selectedValue={lng}
            onSelect={handleLanguageChange}
            width="116px"
            variant="solid"
          />
        </Box>

        <Box flex={1} position="relative" minHeight="100vh" h="auto">
          <Image
            src={lng === "ko" ? "/images/login_logo.png" : "/images/login_logo_en.png"}
            alt="로고"
            layout="fill"
            objectFit="cover"
          />
        </Box>

        <Flex flex={1} bg="white" justifyContent={"center"} minHeight="100vh" h="auto">
          <Flex w="100%" justifyContent={"center"} alignItems={"center"}>
            <VStack spacing={48} align="stretch">
              <TypoGraph variant="title03">{t("login")}</TypoGraph>
              <form onSubmit={handleLogin} style={{ margin: "0 auto" }}>
                <VStack
                  spacing={3}
                  width={inputWidth}
                  animation={isShaking ? `${shakeAnimation} 0.5s` : "none"}
                >
                  <FormControl>
                    <Input
                      hasError={inputErrors.email}
                      w={inputWidth}
                      placeholder={t("id")}
                      name="email"
                      type="text"
                      onChange={handleInputChange}
                      onFocus={() => handleInputFocus("email")}
                    />
                  </FormControl>
                  <FormControl>
                    <Input
                      errorText={loginError ?? ""}
                      hasError={inputErrors.password}
                      w={inputWidth}
                      placeholder={t("pw")}
                      name="password"
                      type="password"
                      onChange={handleInputChange}
                      onFocus={() => handleInputFocus("password")}
                    />
                  </FormControl>
                  <Button
                    variant="primary"
                    w={inputWidth}
                    m="0 auto"
                    type="submit"
                    mt="29px"
                    animation={isShaking ? `${shakeAnimation} 0.5s` : "none"}
                  >
                    {t("login")}
                  </Button>
                </VStack>
              </form>
            </VStack>
          </Flex>
          <Box
            position={"fixed"}
            bottom={"5"}
            display={"flex"}
            w="574px"
            justifyContent={"center"}
            gap={16}
          >
            <TypoGraph variant="label03" color={"gray.700"} display={"inline"}>
              2024 Ⓒ UPSIGHT Co., Ltd.
            </TypoGraph>
            <svg
              width="3"
              height="17"
              viewBox="0 0 3 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.5 1.41797C0.5 0.865684 0.947715 0.417969 1.5 0.417969C2.05228 0.417969 2.5 0.865684 2.5 1.41797V15.418C2.5 15.9703 2.05228 16.418 1.5 16.418C0.947715 16.418 0.5 15.9703 0.5 15.418V1.41797Z"
                fill="#C4CCD6"
              />
            </svg>
            <TypoGraph variant="label03" color={"gray.700"} display={"inline"}>
              official@upsight.co.kr
            </TypoGraph>
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

export default LoginPage;
