"use client";

import { Flex } from "@chakra-ui/react";
import { Button, IconButton, Retry, TypoGraph } from "@upsightdevs/upsight-design-system";
import Image from "next/image";
import { useRouter } from "next/navigation";
import React from "react";

const NotFound = () => {
  const router = useRouter();

  const handleGoBack = () => {
    router.back();
  };

  const handleGoHome = () => {
    router.push("/");
  };

  return (
    <Flex justifyContent="center" alignItems="center" height="100vh">
      <Flex w="616px" h="228px" flexDirection="column" gap="24px" alignItems="center">
        <Flex alignItems="center" gap="32px">
          <Image src="/images/not_found.svg" alt="404" width={160} height={160} />
          <Flex flexDirection="column" gap="8px" w="100%">
            <Flex w="72px" h="50px" justifyContent="center" alignItems="center">
              <TypoGraph variant="title01" color="#212A4A">404</TypoGraph>
            </Flex>
            <Flex h="26px" w="100%" justifyContent="center" alignItems="center">
              <TypoGraph variant="headline03" color="#1F2539">죄송합니다. 현재 찾을 수 없는 페이지에 접속하셨습니다.</TypoGraph>
            </Flex>
            <Flex h="44px" w="291px" flexDirection="column" justifyContent="center">
              <TypoGraph variant="body02">삭제되거나 주소가 잘못 입력되었습니다.</TypoGraph>
              <TypoGraph variant="body02">이전 페이지로 돌아가거나, 홈페이지로 이동해 주세요</TypoGraph>
            </Flex>
          </Flex>
        </Flex>
        <Flex w="229px" h="44px" gap="12px">
          <IconButton w="133px" h="44px" variant="assistive" onClick={handleGoBack}>이전 페이지<Retry /></IconButton>
          <Button w="88px" h="44px" variant="outlined" onClick={handleGoHome}>대시보드</Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default NotFound;
