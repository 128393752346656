/** @jsxImportSource @emotion/react */
"use client";

import { css, keyframes } from "@emotion/react";

/** @jsxImportSource @emotion/react */

const dash = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const LoadingImg = css`
  color: white;
  text-align: center;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 50%;
  left: calc(50% + 100px);
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  z-index: 99;
`;

const spinner = css`
  animation: ${rotate} 2s linear infinite;
`;

const path = css`
  stroke: url(#grad1);
  stroke-dasharray: 150;
  stroke-dashoffset: 0;
  animation: ${dash} 1.5s ease-in-out infinite;
`;

const Loading = () => {
  return (
    <div css={[LoadingImg]}>
      <svg className="spinner" width={80} viewBox="0 0 50 50" css={[spinner]}>
        <defs>
          <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" style={{ stopColor: "#ffffff", stopOpacity: 0 }} />
            <stop
              offset="100%"
              style={{ stopColor: "rgba(38, 62, 137)", stopOpacity: 1 }}
            />
          </linearGradient>
        </defs>
        <circle
          className="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="5"
          css={[path]}
        />
      </svg>
    </div>
  );
};

export default Loading;
