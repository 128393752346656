import { useMutation, useQueryClient } from "@tanstack/react-query";

import { AuthApi } from "@/utils/fetchData";

const postLogin = async (formData: FormData) => {
  const response = await AuthApi.postLogin(formData);

  if (response.data.token.access) {
    localStorage.setItem("access_token", response.data.token.access);
    localStorage.setItem("refresh_token", response.data.token.refresh);
    localStorage.setItem("userid", response.data.userid);
    localStorage.setItem("username", response.data.username);
  }

  return response;
};

export const usePostLogin = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postLogin,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["login"] });
    },
  });
};
